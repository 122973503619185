import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import { Hero, Fonts, Footer, SiteHeader } from '../components'

import '../style/all.scss'

const NotFoundPage = ({ data }) => (
  <Fragment>
    <Fonts />
    <Helmet>
      <html lang="en" />
      <title>Page Not Found | SF Dental</title>
      <meta name="robots" content="noindex, nofollow" />
    </Helmet>
    <div id="container">
      <SiteHeader location={data.site.siteMetadata.location} />
      <div id="content" className={`content-page page-404`}>
        <Hero
          header={`404: Page Not Found`}
          subheader={`Whoops, we&rsquo;re not sure how you got here&hellip; Please try another page.`}
          featuredImage={{ ...data, alt: 'Golden Gate Bridge' }}
          bgColor="black"
        />
      </div>
      <Footer location={data.site.siteMetadata.location} />
    </div>
  </Fragment>
)

export const pageQuery = graphql`
  query NotFoundPageTemplate {
    site {
      ...siteMeta
    }
    desktop: file(name: { regex: "/^shutterstock_23455360.desktop.jpg/" }) {
      ...desktopHeroImage
    }
    tablet: file(name: { regex: "/^shutterstock_23455360.tablet.jpg/" }) {
      ...tabletHeroImage
    }
    mobile: file(name: { regex: "/^shutterstock_23455360.mobile.jgp/" }) {
      ...mobileHeroImage
    }
  }
`

export default NotFoundPage
